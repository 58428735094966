import { NomeAplicativoEnum } from "../enums";
import { JsonERP3FilialObterModel, JsonERP3GetFaturamentoDiario, JsonERP3GetPosicaoDoDia } from "./ERP3/jsonErp3Models";
import { JsonSFTAppConfConcluirModel, JsonSFTAppConfConferenteObterModel, JsonSFTAppConfGerarModel, JsonSFTAppConfNotaObterModel, JsonSFTAppConfObterModel } from "./SFT/jsonSftAppConfModels";
import { JsonSFTFilialObterModel, JsonSFTGetParametrosModel, JsonSFTGetProdutoModel } from "./SFT/jsonSftModels";
import {
    JsonFilialObterModel, JsonGetAroModel, JsonGetCarteiraClienteModel, JsonGetConhecimentoEmpresaModel, JsonGetContatoModel, JsonGetEnderecoModel,
    JsonGetEntidadeClienteModel, JsonGetEntidadeModel, JsonGetEstadoModel, JsonGetHistoricoResumidoModel, JsonGetMedidaPneuModel, JsonGetMunicipioModel,
    JsonGetOrdemServicoModel,
    JsonGetParametrosModel, JsonGetPermissoesModel, JsonGetPrecoModel, JsonGetPrismaModel, JsonGetProdutoModel, JsonGetRegimeTributacaoModel,
    JsonGetTabelaPrecoModel, JsonGetTipoConsumoModel, JsonGetTipoEmailModel, JsonGetTipoEnderecoModel, JsonGetVeiculoModel, JsonGetVeiculoModeloModel,
    JsonRecalcularPrecoModel, JsonSetEntidadeModel, JsonSetOrdemServicoModel, JsonSetVeiculoModel, JsonValidacaoItemModel
} from "./WAC/jsonWacModels";

export function handlerJsonGetVeiculoModel(app, deviceId, veicId, veicPlaca, offset, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetVeiculoModel(deviceId, veicId, veicPlaca, offset, limit)
        default:
            return {}
    }
}

export function handlerJsonGetVeiculoModeloModel(app, deviceId, veicId, veicPlaca, offset, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetVeiculoModeloModel(deviceId, veicId, veicPlaca, offset, limit)
        default:
            return {}
    }
}

export function handlerJsonGetAroModel(app, deviceId, id, offset, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetAroModel(deviceId, id, offset, limit)
        default:
            return {}
    }
}

export function handlerJsonGetMedidaPneuModel(app, deviceId, id, offset, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetMedidaPneuModel(deviceId, id, offset, limit)
        default:
            return {}
    }
}

export function handlerJsonSetVeiculoModel(app, deviceId, filialId, objVeiculo) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonSetVeiculoModel(deviceId, filialId, objVeiculo)
        default:
            return {}
    }
}

export function handlerJsonGetEntidadeModel(app, deviceId, entidaId, entidaNome, cpfCnpj, offset, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetEntidadeModel(deviceId, entidaId, entidaNome, cpfCnpj, offset, limit)
        default:
            return {}
    }
}

export function handlerJsonGetEntidadeClienteModel(app, deviceId, entidaId, entidaNome, cpfCnpj, offset, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetEntidadeClienteModel(deviceId, entidaId, entidaNome, cpfCnpj, offset, limit)
        default:
            return {}
    }
}

export function handlerJsonGetEstadoModel(app, deviceId, estadoId, offset = 0, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetEstadoModel(deviceId, estadoId, offset, limit)
        default:
            return {}
    }
}

export function handlerJsonGetMunicipioModel(app, deviceId, estadoId, municiId, offset = 0, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetMunicipioModel(deviceId, estadoId, municiId, offset = 0, limit)
        default:
            return {}
    }
}

export function handlerJsonGetRegimeTributacaoModel(app, deviceId, idFilial, regimeTribId, offset = 0, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetRegimeTributacaoModel(deviceId, idFilial, regimeTribId, offset = 0, limit)
        default:
            return {}
    }
}

export function handlerJsonGetConhecimentoEmpresaModel(app, deviceId, conEmpId, offset = 0, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetConhecimentoEmpresaModel(deviceId, conEmpId, offset = 0, limit)
        default:
            return {}
    }
}

export function handlerJsonGetCarteiraClienteModel(app, deviceId, carCliId, offset = 0, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetCarteiraClienteModel(deviceId, carCliId, offset = 0, limit)
        default:
            return {}
    }
}

export function handlerJsonGetTipoConsumoModel(app, deviceId, tipConsId, offset = 0, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetTipoConsumoModel(deviceId, tipConsId, offset = 0, limit)
        default:
            return {}
    }
}

export function handlerJsonGetTipoEnderecoModel(app, deviceId, tipEndId, offset = 0, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetTipoEnderecoModel(deviceId, tipEndId, offset = 0, limit)
        default:
            return {}
    }
}

export function handlerJsonGetTabelaPrecoModel(app, deviceId, tabPrecoId, offset = 0, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetTabelaPrecoModel(deviceId, tabPrecoId, offset = 0, limit)
        default:
            return {}
    }
}

export function handlerJsonGetEnderecoModel(app, deviceId, endereId, entidaId, offset = 0, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetEnderecoModel(deviceId, endereId, entidaId, offset = 0, limit)
        default:
            return {}
    }
}

export function handlerJsonGetContatoModel(app, deviceId, contatId, entidaId, offset = 0, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetContatoModel(deviceId, contatId, entidaId, offset = 0, limit)
        default:
            return {}
    }
}

export function handlerJsonGetTipoEmailModel(app, deviceId, tipEmailId, offset = 0, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetTipoEmailModel(deviceId, tipEmailId, offset = 0, limit)
        default:
            return {}
    }
}

export function handlerJsonSetEntidadeModel(app, deviceId, filialId, data) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonSetEntidadeModel(deviceId, filialId, data)
        default:
            return {}
    }
}

export function handlerJsonGetPermissoesModel(app, deviceId, aplicaId, aplTelId, offset = 0, limit = 1000) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetPermissoesModel(deviceId, aplicaId, aplTelId, offset, limit)
        default:
            return {}
    }
}

export function handlerJsonGetHistoricoResumidoModel(app, deviceId, entidaId, veiculoId, vendedorId, offset, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetHistoricoResumidoModel(deviceId, entidaId, veiculoId, vendedorId, offset, limit)
        default:
            return {}
    }
}

export function handlerJsonGetPrismaModel(filialID, somenteDisponivel, app, deviceId, tabPrecoId, offset = 0, limit) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetPrismaModel(filialID, somenteDisponivel, deviceId, offset = 0, limit)
        default:
            return {}
    }
}

export function handlerJsonGetParametrosModel(app, deviceId, parametroId) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetParametrosModel(deviceId, parametroId)
        case NomeAplicativoEnum.ibsConsultaProdutos:
            return JsonSFTGetParametrosModel(deviceId, parametroId)
        case NomeAplicativoEnum.goedertPreVenda:
            return JsonSFTGetParametrosModel(deviceId, parametroId)
        default:
            return {}
    }
}

export function handlerJsonGetProdutoModel(app, deviceId, filialId, tabPrecoId, somenteAtivo, somenteComEstoque, regEspecifico, offset = 0, limit, subProId, conPagId, eanDun) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetProdutoModel(deviceId, filialId, tabPrecoId, somenteAtivo, somenteComEstoque, regEspecifico, offset, limit, subProId, conPagId, eanDun)
        case NomeAplicativoEnum.ibsConsultaProdutos:
            return JsonSFTGetProdutoModel(deviceId, filialId, tabPrecoId, somenteAtivo, somenteComEstoque, regEspecifico, offset, limit, subProId, conPagId, eanDun)
        case NomeAplicativoEnum.goedertPreVenda:
            return JsonSFTGetProdutoModel(deviceId, filialId, tabPrecoId, somenteAtivo, somenteComEstoque, regEspecifico, offset, limit, subProId, conPagId, eanDun)
        default:
            return {}
    }
}

export function handlerJsonGetPrecoModel(app, deviceId, subProId, tabPrecoId, conPagId, qtde, entidaId, idFilial) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetPrecoModel(deviceId, subProId, tabPrecoId, conPagId, qtde, entidaId, idFilial)
        default:
            return {}
    }
}

export function handlerJsonValidacaoItemModel(app, deviceId, order) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonValidacaoItemModel(deviceId, order)
        default:
            return {}
    }
}

export function handlerJsonRecalcularPrecoModel(app, deviceId, data) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonRecalcularPrecoModel(deviceId, data)
        default:
            return {}
    }
}

export function handlerJsonSetOrdemServicoModel(app, deviceId, data) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonSetOrdemServicoModel(deviceId, data)
        default:
            return {}
    }
}

export function handlerJsonFilialObterModel(app, deviceId) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonFilialObterModel(deviceId)
        case NomeAplicativoEnum.ibsConsultaProdutos:
            return JsonSFTFilialObterModel(deviceId)
        case NomeAplicativoEnum.ibsConferencia:
            return JsonSFTFilialObterModel(deviceId)
        case NomeAplicativoEnum.goedertPreVenda:
            return JsonSFTFilialObterModel(deviceId)
        case NomeAplicativoEnum.volcanErp:
            return JsonERP3FilialObterModel(deviceId)
        default:
            return {}
    }
}

export function handlerGerarConferencia(app, deviceId, listaDavs, listaFaturamento, conferenteId) {
    switch (app) {
        case NomeAplicativoEnum.ibsConferencia:
            return JsonSFTAppConfGerarModel(deviceId, listaDavs, listaFaturamento, conferenteId)
        default:
            return {}
    }
}

export function handleConferenteObter(app, deviceId) {
    switch (app) {
        case NomeAplicativoEnum.ibsConferencia:
            return JsonSFTAppConfConferenteObterModel(deviceId)
        default:
            return {}
    }
}

export function handleNotaObter(app, deviceId, faturamentoNfeChave) {
    switch (app) {
        case NomeAplicativoEnum.ibsConferencia:
            return JsonSFTAppConfNotaObterModel(deviceId, faturamentoNfeChave)
        default:
            return {}
    }
}

export function handleConferenciaObter(app, deviceId, conferenciaId) {
    switch (app) {
        case NomeAplicativoEnum.ibsConferencia:
            return JsonSFTAppConfObterModel(deviceId, conferenciaId)

    }
}

export function handlerConcluirConferencia(app, deviceId, listaProdutos, conferId) {
    switch (app) {
        case NomeAplicativoEnum.ibsConferencia:
            return JsonSFTAppConfConcluirModel(deviceId, listaProdutos, conferId)
        default:
            return {}
    }
}

export function handlerJsonGetOrdemServicoModel(app, deviceId, ordserId, filialID) {
    switch (app) {
        case NomeAplicativoEnum.ibsAutoCenter:
            return JsonGetOrdemServicoModel(deviceId, ordserId, filialID)
        default:
            return {}
    }
}

export function handlerJsonGetFaturamentoDiario(app, deviceId, filialId) {
    switch (app) {
        case NomeAplicativoEnum.volcanErp:
            return JsonERP3GetFaturamentoDiario(deviceId, filialId)
        default:
            return {}
    }
}

export function handlerJsonGetPosicaoDoDia(app, deviceId, filialId) {
    switch (app) {
        case NomeAplicativoEnum.volcanErp:
            return JsonERP3GetPosicaoDoDia(deviceId, filialId)
        default:
            return {}
    }
}