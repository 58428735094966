import { useContext, useEffect, useState } from "react"
import { Dimensions, Keyboard, View } from "react-native"
import { ThemeContext } from "../../../contexts/themeContext"
import { IBSWsSettingsContext } from "../../../contexts/ibsWsSettingsContext"
import { IBSCompanyContext } from "../../../contexts/ibsCompanyContext"
import { ProgressDialogContext } from "../../../contexts/progressDialogContext"
import { styles } from "./styles"
import { CalcularNrColunasFlatListETamanhoCard } from "../../../utils/utils"
import { parametrosEnum, tipoParam } from "../../../models/parametros";
import { sendAlert } from "../../../services/messagingCenter"
import IBSTextInput from "../../../components/IBSTextInput"
import IBSIconButton from "../../../components/IBSIconButton"
import IBSSwitch from "../../../components/IBSSwitch"
import IBSSeparator from "../../../components/IBSSeparator"
import IBSFlatList from "../../../components/IBSFlatList"
import CardProduto from "../../../cards/cardProduto"
import { handlerJsonGetProdutoModel } from "../../../models/requisitions/handlerRequisitions"
import { OrderContext } from "../../../contexts/orderContext"
import { ParametrosContext } from "../../../contexts/parametrosContext"
import { getItem, setItem } from "../../../services/database/asyncStorageService"
import { usePostRequisition } from "../../../requisitions/wsIBS/usePostRequisition"

export default function ConsultaProdutoBase({ navigation }) {

    const { PostRequisition } = usePostRequisition()

    const { IBSTheme, CustomTheme } = useContext(ThemeContext)
    const { settings, deviceId, nmAplicativo, sistema, idApp } = useContext(IBSWsSettingsContext)
    const { idFilial } = useContext(IBSCompanyContext)
    const { showProgressDialog, hideProgressDialog } = useContext(ProgressDialogContext)
    const { order, orderType } = useContext(OrderContext)
    const { getParametro } = useContext(ParametrosContext)

    const [pesquisa, setPesquisa] = useState('')
    const [arrayProd, setArrayProd] = useState([])
    const [novaConsulta, setNovaConsulta] = useState(false)
    const [offset, setOffset] = useState(0)
    const [searchEmpty, setSearchEmpty] = useState(false)
    const [searchEnd, setSearchEnd] = useState(false)
    const [boolEstoque, setBoolEstoque] = useState(false)
    const [boolAtivos, setBoolAtivos] = useState(true)
    const [boolMaisOpcoes, setBoolMaisOpcoes] = useState(false)
    const [numColumns, setNumColumns] = useState(0)
    const [widthCard, setWidthCard] = useState(0)
    const [screenWidth, setScreenWidth] = useState(Dimensions.get("window").width)
    const [idTabPreco, setIdTabPreco] = useState(0)
    const [custoVisible, setCustoVisible] = useState(false)

    const limit = 50
    const style = styles(IBSTheme, CustomTheme)
    const mostrarCusto = getParametro(parametrosEnum.MostrarPrecoDeCustoProduto, tipoParam.logico)

    useEffect(() => {
        const updateWidth = () => {
            setScreenWidth(Dimensions.get("window").width);
        };

        const eventUpdateWidth = Dimensions.addEventListener("change", updateWidth);
        return () => eventUpdateWidth.remove()
    }, []);

    useEffect(() => {
        getItem(OptionsEnum.estoqueMaiorZero).then(value => setBoolEstoque(value.object ?? false))
        getItem(OptionsEnum.apenasAtivos).then(value => setBoolAtivos(value.object ?? true))
    }, [])

    useEffect(() => {
        if (novaConsulta && arrayProd.length === 0) {
            consultaProduto(0, true)
            setNovaConsulta(false)
        }
    }, [arrayProd, novaConsulta])

    useEffect(() => {
        let [tempNumColumns, tempWidthWindow] = CalcularNrColunasFlatListETamanhoCard(Dimensions.get("window").width, 10, 10, 325)
        setNumColumns(tempNumColumns)
        setWidthCard(tempWidthWindow)
    }, [screenWidth])

    const OptionsEnum = {
        estoqueMaiorZero: 'optionEstoqueMaiorZero',
        apenasAtivos: 'optionApenasAtivos',
    }

    const actionConsulta = async () => {
        Keyboard.dismiss()
        setArrayProd([])
        setSearchEnd(false)
        setNovaConsulta(true)
    }

    const consultaProduto = async (paramOffset, showMessage = false) => {
        if (searchEmpty != false)
            setSearchEmpty(false)

        if (showMessage)
            showProgressDialog('Buscando produtos')

        const ret = await PostRequisition(settings, handlerJsonGetProdutoModel(nmAplicativo, deviceId, idFilial, order?.[orderType?.TabelaPrecoID] ??
            getParametro(parametrosEnum.IDTabelaPrecoPadrao, tipoParam.inteiro), boolAtivos, boolEstoque, pesquisa, paramOffset, limit, null,
            order?.[orderType?.CondicaoPagamentoID]));

        if (showMessage)
            hideProgressDialog();

        if (!ret.success) {
            if (ret.message !== 'Produto não encontrado')
                sendAlert(ret.title, ret.message);
            else {
                if (paramOffset === 0) //consulta vazia
                    setSearchEmpty(true);
                else //Controle para não efetuar novas pesquisas caso tenha chego no fim
                    setSearchEnd(true);
            }
        } else {
            if (ret.object && ret.object.produto && ret.object.produto.length > 0) {
                setArrayProd([...arrayProd, ...ret.object.produto]);
                setOffset(paramOffset + limit);
            }
        }
    }

    const handleOptionEstoque = () => {
        setItem(OptionsEnum.estoqueMaiorZero, !boolEstoque)
        setBoolEstoque(!boolEstoque)
        actionConsulta()
    }

    const handleOptionAtivos = () => {
        setItem(OptionsEnum.apenasAtivos, !boolAtivos)
        setBoolAtivos(!boolAtivos)
        actionConsulta()
    }

    return (
        <View style={style.container}>
            <View style={style.viewRow}>
                <IBSTextInput
                    styleContainer={style.textInputContainer}
                    placeholder={'Informe o Produto (ID, Desc.)'}
                    icon={'pricetags'}
                    iconDirectory={'ionicons'}
                    value={pesquisa}
                    onChangeText={(value) => setPesquisa(value)}
                    onSubmitEditing={() => actionConsulta()}
                    clearButton={true}
                />
                <IBSIconButton
                    icon={'search-outline'}
                    iconDirectory={'ionicons'}
                    style={style.buttonSearch}
                    onPress={() => {
                        actionConsulta()
                    }}
                />
                <IBSIconButton
                    icon={'options-outline'}
                    iconDirectory={'ionicons'}
                    style={style.buttonSearch}
                    onPress={() => {
                        setBoolMaisOpcoes(!boolMaisOpcoes)
                    }}
                />
            </View>
            {boolMaisOpcoes &&
                <View style={style.viewMaisOpcoes}>
                    <IBSSwitch
                        text={'Estoque maior que zero'}
                        textLeft={false}
                        onValueChange={() => handleOptionEstoque()}
                        value={boolEstoque}
                        style={style.switch}
                    />
                    <IBSSwitch
                        text={'Apenas ativos'}
                        textLeft={false}
                        onValueChange={() => handleOptionAtivos()}
                        value={boolAtivos}
                        style={style.switch}
                    />
                </View>
            }
            <IBSSeparator style={style.separador} />
            <IBSFlatList
                keyboardShouldPersistTaps='handled'
                key={numColumns}
                numColumns={numColumns}
                styleText={style.textProdNaoEncontrado}
                data={arrayProd}
                renderItem={({ item, index }) =>
                    <CardProduto
                        produto={{ ...item }}
                        widthCard={widthCard}
                        index={index}
                        numColumns={numColumns}
                        onPress={() => {
                            if (orderType && order?.[orderType.CondicaoPagamentoID])
                                navigation.navigate('AdicionarProduto', { idProduto: item.id })
                        }}
                        mostrarCusto={mostrarCusto}
                        custoVisible={custoVisible}
                        setCustoVisible={setCustoVisible}
                    />}
                keyExtractor={({ id }) => id}
                onEndReached={async () => await consultaProduto(offset)}
                limit={limit}
                searchEmpty={searchEmpty}
                searchEnd={searchEnd}
            />
        </View>
    )
}