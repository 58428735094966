import { createContext, useState } from "react"
import * as FileSystem from 'expo-file-system';
import { Platform } from "react-native";

export const IBSUserContext = createContext({})

export function IBSUserProvider({ children }) {
    const [usrCodigo, setUsrCodigo] = useState('')
    const [usrLogin, setUsrLogin] = useState('')
    const [usrSenha, setUsrSenha] = useState('')
    const [usrNome, setUsrNome] = useState('')
    const [usrEmail, setUsrEmail] = useState('')
    const [usrUsuarioEmail, setUsrUsuarioEmail] = useState('')
    const [usrUsuarioSenhaEmail, setUsrUsuarioSenhaEmail] = useState('')
    const [entVenId, setEntVenId] = useState('')
    const [operadId, setOperadId] = useState('')
    const [operadDescricao, setOperadDescricao] = useState('')
    const [operadTextoQrCode, setOperadTextoQrCode] = useState('')
    const [usrFoto, setUsrFoto] = useState('')
    const [uriUsrFoto, setUriUsrFoto] = useState('')
    const [usrIbs, setUsrIbs] = useState('')
    const [token, setToken] = useState('')

    async function clearUser() {
        if (uriUsrFoto) {
            FileSystem.deleteAsync(uriUsrFoto)
                .then(() => { })
                .catch((error) => console.error('Erro ao deletar imagem do perfil do usuário:', error))
        }
        setUsrCodigo('')
        setUsrLogin('')
        setUsrSenha('')
        setUsrNome('')
        setUsrEmail('')
        setUsrUsuarioEmail('')
        setUsrUsuarioSenhaEmail('')
        setEntVenId('')
        setOperadId('')
        setOperadDescricao('')
        setOperadTextoQrCode('')
        setUsrFoto('')
        setUriUsrFoto('')
        setUsrIbs('')
        setToken('')
    }

    async function setUser(user) {
        if (user?.usr_codigo)
            setUsrCodigo(user.usr_codigo)
        if (user?.usr_login)
            setUsrLogin(user.usr_login)
        if (user?.usr_senha)
            setUsrSenha(user.usr_senha)
        if (user?.usr_nome)
            setUsrNome(user.usr_nome)
        if (user?.usr_email)
            setUsrEmail(user.usr_email)
        if (user?.usr_usuario_email)
            setUsrUsuarioEmail(user.usr_usuario_email)
        if (user?.usr_senha_email)
            setUsrUsuarioSenhaEmail(user.usr_senha_email)
        if (user?.entven_id)
            setEntVenId(user.entven_id)
        if (user?.usr_ibs)
            setUsrIbs(user.usr_ibs)
        if (user?.token)
            setToken(user.token)        
        if (user?.usr_foto) {
            setUsrFoto(user?.usr_foto)

            try {
                let fileUri = '';
                if (Platform.OS === 'web') {
                    // web utilizo imagem fixa da IBS
                } else {
                    fileUri = `${FileSystem.cacheDirectory}${user?.usr_foto?.length}-usrFoto.png`;
                    await FileSystem.writeAsStringAsync(fileUri, user?.usr_foto, { encoding: FileSystem.EncodingType.Base64 });
                }
                setUriUsrFoto(fileUri);
            } catch (error) {
                setUriUsrFoto('')
                console.error('Erro ao salvar imagem do perfil do usuário:', error);
            }
        }
    }

    return (
        <IBSUserContext.Provider value={{
            usrCodigo, setUsrCodigo,
            usrLogin, setUsrLogin,
            usrSenha, setUsrSenha,
            usrNome, setUsrNome,
            usrEmail, setUsrEmail,
            usrUsuarioEmail, setUsrUsuarioEmail,
            usrUsuarioSenhaEmail, setUsrUsuarioSenhaEmail,
            usrFoto, setUsrFoto,
            entVenId, setEntVenId,
            usrIbs, setUsrIbs,
            operadId, setOperadId,
            operadDescricao, setOperadDescricao,
            operadTextoQrCode, setOperadTextoQrCode,
            uriUsrFoto,
            token, setToken,
            clearUser,
            setUser
        }}>
            {children}
        </IBSUserContext.Provider>
    )
}