import AsyncStorage from '@react-native-async-storage/async-storage';
import BuilderReturn from "../../models/buildReturn"

const setItem = async (key, value) => {
    try {
        const jsonValue = JSON.stringify(value)
        await AsyncStorage.setItem(key, jsonValue)
        return BuilderReturn(true)
    } catch (e) {
        BuilderReturn(false, 'Banco de Dados', `Erro ao salvar dados no Storage. Log: ${e}`)
    }
}

const getItem = async (key) => {
    try {
        const jsonValue = await AsyncStorage.getItem(key)
        return BuilderReturn(true, '', '', jsonValue != null ? JSON.parse(jsonValue) : null)
    } catch (e) {
        BuilderReturn(false, 'Banco de Dados', `Erro ao ler dados do Storage. Log: ${e}`)
    }
}

const updateItems = async (keys, values) => {
    try {
        if (keys.length !== values.length) {
            throw new Error('Keys and values arrays must have the same length');
        }

        for (let i = 0; i < keys.length; i++) {
            const item = await getItem(keys[i]);
            const updatedItem = { ...item.object, ...values[i] };
            await setItem(keys[i], updatedItem);
        }

        return BuilderReturn(true)
    } catch (e) {
        BuilderReturn(false, 'Banco de Dados', `Erro ao fazer update no Storage. Log: ${e}`)
    }
}

const deleteItem = async (key) => {
    try {
        await AsyncStorage.removeItem(key);
        return BuilderReturn(true)
    } catch (e) {
        BuilderReturn(false, 'Banco de Dados', `Erro ao deletar item no Storage. Log: ${e}`)
    }
}

const setOrUpdateUserToken = async (token) => {
    try {
        const result = await getItem('user');
        const updatedUser = { ...result.object, token }
        await setItem('user', updatedUser);
        return BuilderReturn(true);
    } catch (e) {
        return BuilderReturn(false, 'Banco de Dados', `Erro ao adicionar/atualizar token no Storage. Log: ${e}`);
    }
}

export { setItem, getItem, updateItems, deleteItem, setOrUpdateUserToken };