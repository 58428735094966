import ApiService from "../../services/apiService";
import BuildReturn from "../../models/buildReturn";
import { descriptografar } from "../../utils/utils";
import { CHAVE_PASS_USER } from "@env"
import { Platform } from "react-native";
import GetToken from "./getTokenWsV3";

export default async function PostRequisition(settings, json, retry = 3, setToken, usrToken, timeout = 10000) {

    function jsonToQueryString(json, exclude = []) {
        const buildQueryString = (obj, prefix = '') => {
            return Object.keys(obj).reduce((acc, key) => {
                if (exclude.includes(key)) return acc;
                const value = obj[key];
                const prefixedKey = prefix ? `${prefix}[${key}]` : key;

                if (value === null || value === undefined) return acc // TODO: Testar com algumas situações extremas

                if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                    if (prefix === 'data') {
                        acc.push(`${encodeURIComponent(prefixedKey)}=${encodeURIComponent(JSON.stringify(value))}`);
                    } else {
                        acc.push(buildQueryString(value, prefixedKey));
                    }
                } else if (Array.isArray(value)) {
                    acc.push(`${encodeURIComponent(prefixedKey)}=${encodeURIComponent(JSON.stringify(value))}`);
                } else {
                    acc.push(`${encodeURIComponent(prefixedKey)}=${encodeURIComponent(value)}`);
                }

                return acc;
            }, []).join('&');
        };

        const flattenedJson = { ...json, ...(json.data || {}) };
        delete flattenedJson.data;

        return buildQueryString(flattenedJson);
    }

    let options = {}
    options.headers = {
        'Content-Type': 'application/json',
        'cnpjnginx': settings.cnpj
    }
    options.json = true
    options.resolveWithFullResponse = true
    if (!settings.wsV3 && settings.basicAuth) {
        if (Platform.OS === 'web') {
            options.auth = {}
            options.auth.username = settings.basicAuth.username
            options.auth.password = descriptografar(settings.basicAuth.password, CHAVE_PASS_USER)
        } else {
            options.auth = settings.basicAuth
        }
    }

    try {
        let result = {}
        let method = 'POST'
        let endpoint = settings.endpoint

        if (settings.wsV3) {
            let token

            if (setToken) {
                token = await GetToken(settings, setToken, usrToken)
                options.headers['x-auth-token'] = token
            }

            if (!token)
                return BuildReturn(false, 'Token não encontrado', 'Token não encontrado para consumir a API IBS')

            endpoint = `${json.route_ws_v3}/${settings.endpoint}`

            if (json?.method_ws_v3 && json?.method_ws_v3 === 'GET') { //Transformar props do obj em param de URL
                method = 'GET'
                const queryString = jsonToQueryString(json, ['action', 'method_ws_v3', 'route_ws_v3']);
                endpoint += `?${queryString}`;
            }
        }

        while (retry > 0) {
            try {
                if (method === 'GET')
                    result = await ApiService(settings.baseUrl, false, timeout).get(endpoint, options)
                else
                    result = await ApiService(settings.baseUrl, false, timeout).post(endpoint, JSON.stringify(json), options)
                break
            } catch (error) {
                if (error.code === 'ECONNABORTED' || error?.toString().indexOf('manualTimeout') > 0) {
                    await new Promise(resolve => setTimeout(resolve, 5000)); // Aguarda 5 segundos
                    retry-- //Em caso de Timeout (Axios/Manual), é feito mais duas tentativas de envio
                } else
                    retry = 0

                if (retry <= 0)
                    throw error;
            }
        }

        if (!result?.data)
            return BuildReturn(false, 'Exceção ao consumir API IBS', `API não retornou nenhum dado`)

        if (!result?.data.success) {
            if (result.data.object && result.data.object?.message)
                return BuildReturn(false, 'Mensagem API IBS', `${result.data.object?.message}`)

            return BuildReturn(false, 'Consumo API IBS', 'API não retornou nenhum registro')
        }

        return BuildReturn(true, '', '', result.data.object)
    } catch (error) {
        if (error?.response?.data?.object?.message)
            return BuildReturn(false, 'Erro Interno API IBS', `Log: ${error?.response?.data?.object?.message.replace(/\\n/g, '\n')}`)
        else if (error?.response?.data?.error?.message)
            return BuildReturn(false, 'Mensagem API IBS', `${error?.response?.data?.error?.message.replace(/\\n/g, '\n')}`)
        else if (error?.response?.data)
            return BuildReturn(false, 'Erro Interno API IBS', `Log: ${error?.response?.data}`)
        else if (error?.response?.data?.message)
            return BuildReturn(false, 'Exceção ao consumir API IBS', `Log: ${error?.response?.data?.message}`)
        else if (error?.toString().indexOf('timeout of') > 0 && error?.toString().indexOf('exceeded') > 0)
            return BuildReturn(false, 'Tempo Esgotado', `O tempo limite para a comunicação com o servidor esgotou, verifique sua conexão.`)
        else if (error?.toString().indexOf('manualTimeout') > 0) //Cancelamento que faço manual caso o timeout do axios falhe
            return BuildReturn(false, 'Operação Cancelada', `O servidor recebeu a solicitação, mas o aplicativo não obteve uma resposta no tempo previsto.`)
        else if (error?.toString().indexOf('Network Error') > 0) //Esse erro acontece geralmente por falta de internet, pode acontecer também ao tentar consumir um link http
            return BuildReturn(false, 'Erro na solicitação', `Não foi possível solicitar o servidor, verifique sua conexão com a internet.\n\nLog: ${error}`)
        else
            return BuildReturn(false, 'Exceção ao consumir API IBS', `Log: ${error}`)
    }
}