import { useContext } from "react"
import { ThemeContext } from "../../contexts/themeContext"
import { styles } from "./styles"
import { View } from "react-native"

export default function IBSSeparator(props) {
    const { IBSTheme, CustomTheme } = useContext(ThemeContext)
    const style = styles(IBSTheme, CustomTheme)

    return (
        <View style={[props?.vertical ? style.separadorVertical : style.separador, props?.style ? props.style : undefined]}/>
    )
}

/*

    PROPS

    style - Estilo personalizado do separador

*/