import { SECRET_API_WS_V3, CHAVE_PASS_USER } from "@env"
import { decodeJWTPayload, descriptografar, getCurrentDateInSeconds } from "../../utils/utils"
import ApiService from "../../services/apiService"
import { Platform } from "react-native"
import { updateToken } from "../../services/database/tables/user"
import { Sistema } from "../../models/enums"
import { setOrUpdateUserToken } from "../../services/database/asyncStorageService"

export default async function GetToken(settings, setToken, usrToken) {
    try {
        let tokenMissingOrOutdated = false

        if (!usrToken)
            tokenMissingOrOutdated = true
        else {
            const jsonInfToken = decodeJWTPayload(usrToken)

            //Se token expirou, ou foi alterado o usuário
            if (jsonInfToken.exp <= getCurrentDateInSeconds())
                tokenMissingOrOutdated = true
        }

        if (tokenMissingOrOutdated) {
            const options = {}
            let json
            let url

            if (settings.sistema === Sistema.sisfatura) {
                options.headers = {
                    'Content-Type': 'application/json',
                    'cnpjnginx': settings.cnpj,
                }

                if (Platform.OS === 'web') {
                    options.auth = {}
                    options.auth.username = settings.basicAuth.username
                    options.auth.password = descriptografar(settings.basicAuth.password, CHAVE_PASS_USER)
                } else {
                    options.auth = settings.basicAuth
                }

                url = `${settings.baseUrl}auth`
            } else {
                options.headers = {
                    'Content-Type': 'application/json',
                    'x-auth-secret': SECRET_API_WS_V3
                }

                json = {
                    "cnpj": settings.cnpj,
                    "usuario": settings.basicAuth.username,
                    "senha": Platform.OS === 'web' ? descriptografar(settings.basicAuth.password, CHAVE_PASS_USER) : settings.basicAuth.password
                }

                url = settings.urlTokenWsV3
            }

            const result = await ApiService(url, false, 10000).post('', json ? JSON.stringify(json) : {}, options)
            console.log('result', result)

            const resultData = result?.data

            if (!resultData?.success)
                throw new Error(`Exceção ao tentar obter token ${settings.sistema === Sistema.sisfatura ? resultData?.object?.message : resultData?.error?.message}`)

            const newToken = settings.sistema === Sistema.sisfatura ? resultData.object?.token : resultData.data

            if (Platform.OS === 'web')
                setOrUpdateUserToken(newToken)
            else
                updateToken(newToken)

            setToken(newToken)

            return newToken
        } else {
            return usrToken
        }
    } catch (error) {

        let msgError = error

        if (error?.response?.data?.error?.message) { //retorno ws erp3
            msgError = error?.response?.data?.error?.message?.replace(/\\n/g, '\n')
            if (msgError?.indexOf('FrUsuario não encontrado') >= 0)
                throw new Error("Usuário não encontrado")
        } else if (error?.response?.data?.object?.message) //retorno ws sisfatura
            msgError = error?.response?.data?.object?.message

        throw new Error(msgError)
    }
}