import { useContext } from "react";
import { IBSUserContext } from "../../contexts/ibsUserContext";
import postRequisitionFunction from "./postRequisition";

export function usePostRequisition(timeout = 10000) {
    const { token, setToken } = useContext(IBSUserContext)

    const PostRequisition = async (settings, json, retry = 3) => {
        return await postRequisitionFunction(settings, json, retry, setToken, token, timeout);
    };

    return { PostRequisition };
}