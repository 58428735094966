export function JsonSFTAppLoginModel(deviceId) {
    return {
        "action": "app-login",
        "data": {},
        "device": deviceId,
        "route_ws_v3": "login"
    }
}

export function JsonSFTAppLogoutModel(deviceId) {
    return {
        "action": "app-logout",
        "data": {},
        "device": deviceId,
        "route_ws_v3": "logout"
    }
}

export function JsonSFTFilialObterModel(deviceId) {
    return {
        "action": "filial-obter",
        "device": deviceId,
        "method_ws_v3": "GET",
        "route_ws_v3": "filial"
    }
}

export function JsonSFTGetProdutoModel(deviceId, filialId, tabPrecoId, somenteAtivo = true, somenteComEstoque = false, regEspecifico, offset = 0, limit, subProId, conPagId, eanDun) {
    let objData = {}

    if (filialId)
        objData['filial_id'] = filialId
    if (tabPrecoId)
        objData['tabpre_id'] = tabPrecoId
    if (regEspecifico)
        objData['registro_especifico'] = regEspecifico
    if (subProId)
        objData['subpro_id'] = subProId
    if (eanDun)
        objData['ean_dun'] = eanDun
    if (conPagId)
        objData['conpag_id'] = conPagId
    if (limit)
        objData['limit'] = limit

    objData['somente_ativo'] = somenteAtivo
    objData['somente_com_estoque'] = somenteComEstoque
    objData['offset'] = offset

    return {
        "action": "get-produto",
        "data": objData,
        "device": deviceId,
        "method_ws_v3": "GET",
        "route_ws_v3": "produto"
    }
}

export function JsonSFTGetParametrosModel(deviceId, parametroId) {
    return {
        "action": "get-parametro",
        "data": {
            "parame_id": parametroId
        },
        "device": deviceId,
        "method_ws_v3": "GET",
        "route_ws_v3": "parametro"
    }
}