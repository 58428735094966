import React, { createContext, useContext, useEffect, useState } from 'react';
import { IBSWsSettingsContext } from './ibsWsSettingsContext';
import { handlerJsonGetParametrosModel } from '../models/requisitions/handlerRequisitions';
import { sendAlert } from '../services/messagingCenter';
import { parametrosGerais, parametrosGoedertPreVenda, parametrosIBSAutoCenter, parametrosIBSConsultaProdutos, paramsObrigatoriosGoedertPreVenda, paramsObrigatoriosIBSAutoCenter, paramsObrigatoriosIBSConsultaProdutos, tipoParam } from '../models/parametros';
import { NomeAplicativoEnum } from '../models/enums';
import { ProgressDialogContext } from './progressDialogContext';
import { usePostRequisition } from '../requisitions/wsIBS/usePostRequisition';

export const ParametrosContext = createContext({});

export function ParametrosProvider({ children }) {

    const { settings, deviceId, nmAplicativo } = useContext(IBSWsSettingsContext)
    const { showProgressDialog, hideProgressDialog } = useContext(ProgressDialogContext)

    const [parametros, setParametros] = useState([]);
    const [parametrosApp, setParametrosApp] = useState([]);
    const [nrCasasDecimais, setNrCasasDecimais] = useState(0);
    const [possuiParamApp, setPossuiParamApp] = useState(true);

    const { PostRequisition } = usePostRequisition()

    useEffect(() => {
        if (parametros?.length > 0)
            setNrCasasDecimais(getNrCasasDecimais())
    }, [parametros])

    useEffect(() => {
        if (parametros?.length > 0 && (!possuiParamApp || parametrosApp?.length > 0)) {
            verificarParametrosObrigatorios()
        }
    }, [parametros, parametrosApp, possuiParamApp])

    function getParametros(forcarAtualizacao = false, boolShowProgress = false) {
        //Parâmetros gerais do sistema
        if (parametros?.length <= 0 || forcarAtualizacao) {
            let idsParamsGerais = []

            Object.entries(parametrosGerais).forEach(([key, value]) => {
                idsParamsGerais.push(value)
            })

            if (boolShowProgress)
                showProgressDialog('Atualizando Parâmetros')

            PostRequisition(settings, handlerJsonGetParametrosModel(nmAplicativo, deviceId, idsParamsGerais)).then((retParam) => {
                if (boolShowProgress)
                    hideProgressDialog()

                if (!retParam.success)
                    sendAlert(retParam.title, `Não conseguiu buscar os parâmetros gerais\n\n${retParam.message}`)
                else {
                    if (retParam.object?.parametro && retParam.object?.parametro?.length > 0)
                        setParametros(retParam.object.parametro)

                    if (boolShowProgress)
                        sendAlert('Parâmetros', 'Parâmetros atualizados com sucesso')
                }
            })
        }

        //Parâmetros específicos do app
        if (parametrosApp?.length <= 0 || forcarAtualizacao) {
            let idsParams = []
            let objParams = {}

            switch (nmAplicativo) {
                case NomeAplicativoEnum.ibsAutoCenter:
                    objParams = parametrosIBSAutoCenter
                    break
                case NomeAplicativoEnum.goedertPreVenda:
                    objParams = parametrosGoedertPreVenda
                    break
                case NomeAplicativoEnum.ibsConsultaProdutos:
                    objParams = parametrosIBSConsultaProdutos
                    break
            }

            Object.entries(objParams).forEach(([key, value]) => {
                idsParams.push(value)
            })

            if (idsParams.length > 0) {
                PostRequisition(settings, handlerJsonGetParametrosModel(nmAplicativo, deviceId, idsParams)).then((retParamApp) => {
                    if (!retParamApp.success)
                        sendAlert(retParamApp.title, `Não conseguiu buscar os parâmetros do aplicativo\n\n${retParamApp.message}`)
                    else {
                        if (retParamApp.object?.parametro && retParamApp.object?.parametro?.length > 0)
                            setParametrosApp(retParamApp.object.parametro)
                    }
                })
            } else {
                setPossuiParamApp(false)
            }
        }
    }

    function verificarParametrosObrigatorios() {
        let paramsObrigatorios = []
        let paramsFaltantes = []

        switch (nmAplicativo) {
            case NomeAplicativoEnum.ibsAutoCenter:
                paramsObrigatorios = paramsObrigatoriosIBSAutoCenter
                break
            case NomeAplicativoEnum.goedertPreVenda:
                paramsObrigatorios = paramsObrigatoriosGoedertPreVenda
                break
            case NomeAplicativoEnum.ibsConsultaProdutos:
                paramsObrigatorios = paramsObrigatoriosIBSConsultaProdutos
                break
        }

        if (paramsObrigatorios?.length > 0) {
            for (const paramObrig of paramsObrigatorios) {
                const retParam = parametrosApp.find(p => p.param_id === paramObrig.param_id)?.[paramObrig.param_tipo]
                    ?? parametros.find(p => p.param_id === paramObrig.param_id)?.[paramObrig.param_tipo]

                if (!retParam) {
                    paramsFaltantes.push(paramObrig)
                }
            }
        }

        if (paramsFaltantes.length > 0) {
            sendAlert('Parâmetros', 'Parâmetros obrigatórios não foram configurados: \n\n' + paramsFaltantes.map(p => `${p.param_id} - ${p.param_enum}`).join('\n'))
        }
    }

    function getParametro(nmParam, tpParam) {
        let param = parametrosGerais[nmParam] ?? 0
        let paramApp = 0

        switch (nmAplicativo) {
            case NomeAplicativoEnum.ibsAutoCenter:
                paramApp = parametrosIBSAutoCenter[nmParam]
                break
            case NomeAplicativoEnum.goedertPreVenda:
                paramApp = parametrosGoedertPreVenda[nmParam]
                break
            case NomeAplicativoEnum.ibsConsultaProdutos:
                paramApp = parametrosIBSConsultaProdutos[nmParam]
                break
        }

        //Tenta encontrar no array de parâmetros do aplicativo, caso não encontre, busca no array de parâmetros gerais
        let retParam = parametrosApp.find(p => p.param_id === paramApp)?.[tpParam] ?? parametros.find(p => p.param_id === param)?.[tpParam]
        if (tpParam == tipoParam.logico && (retParam || retParam == 0))
            retParam = retParam == 0 ? false : true

        return retParam
    }

    function getNrCasasDecimais() {
        let qtdCasasDecimais = parametros.find(p => p.param_id === 110)[tipoParam.inteiro]
        if (!qtdCasasDecimais || qtdCasasDecimais <= 0 || qtdCasasDecimais > 4)
            qtdCasasDecimais = 4

        return qtdCasasDecimais
    }

    return (
        <ParametrosContext.Provider value={{
            nrCasasDecimais,
            getParametros,
            getParametro,
            getNrCasasDecimais,
        }}>
            {children}
        </ParametrosContext.Provider>
    );
};
