import axios from "axios";

export default function ApiService(baseUrl, withCredentials = false, timeout) {
    const manualTimeout = timeout + 10000

    const api = axios.create({
        baseURL: baseUrl,
        timeout: 0,
        withCredentials: withCredentials
    })

    //Tratamento de timeout manual, Se o servidor aceitou requisição mas não responde, não entra no timeout padrão do Axios
    api.interceptors.request.use((config) => {
        config.timeout = timeout
        config.cancelToken = new axios.CancelToken((cancel) => {
            const timer = setTimeout(() => {
                cancel('manualTimeout')
            }, manualTimeout)
        })
        
        return config
    })

    return api
}

/*

    PROPS:

    baseURL - URL completa do consumo
    withCredentials - Com o valor false, o axios manda o cookie nulo, o ws do erp espera dessa forma e cria uma nova sessão a cada requisição

*/