import { StyleSheet } from "react-native"

export const styles = (theme, customTheme, font) => {
    return StyleSheet.create({
        safeAreaView: {
            flex: 1,
            backgroundColor: customTheme?.primaryColor ? customTheme.primaryColor : theme.primaryColor,
        },
        itemPicker: {
            fontFamily: font,
            fontSize: 16,
            color: customTheme?.picker?.textColor ? customTheme.picker.textColor : theme.picker.textColor
        },
        overlay: {
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        modalContainer: {
            paddingHorizontal: 10,
        },
        modalContaineriOs: {
            width: '100%',
        },
        button: {
            height: 40,
            marginLeft: 10,
        },
        text: {
            marginBottom: 11,
            paddingHorizontal: 5,
        },
        separador: {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            marginBottom: 11,
        },
        listContent: {
            flexGrow: 1,
        },
        blurContainer: {
            paddingHorizontal: 10,
            paddingVertical: 15
        },
        searchTextInput: {
            marginBottom: 15,
        },
        viewRow: {
            flexDirection: 'row',
            justifyContent: 'flex-end'
        }
    })
}