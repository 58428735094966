export function JsonERP3AppLoginModel(deviceId) {
    return {
        "action": "app-login",
        "data": {},
        "device": deviceId,
        "route_ws_v3": "login"
    }
}

export function JsonERP3AppLogoutModel(deviceId) {
    return {
        "action": "app-logout",
        "data": {},
        "device": deviceId,
        "route_ws_v3": "logout"
    }
}

export function JsonERP3FilialObterModel(deviceId) {
    return {
        "action": "filial-obter",
        "device": deviceId,
        "method_ws_v3": "GET",
        "route_ws_v3": "filial"
    }
}

export function JsonERP3GetParametrosModel(deviceId, parametroId) {
    return {
        "action": "get-parametro",
        "data": {
            "parame_id": parametroId
        },
        "device": deviceId,
        "method_ws_v3": "GET",
        "route_ws_v3": "parametro"
    }
}

export function JsonERP3GetFaturamentoDiario(deviceId, filialId) {
    return {
        "action": "get-faturamento-diario",
        "data": {
            "filial_id": filialId
        },
        "device": deviceId,
        "method_ws_v3": "GET",
        "route_ws_v3": "reports-app-volcan-erp/faturamento-diario"
    }
}

export function JsonERP3GetPosicaoDoDia(deviceId, filialId) {
    return {
        "action": "get-posicao-do-dia",
        "data": {
            "filial_id": filialId
        },
        "device": deviceId,
        "method_ws_v3": "GET",
        "route_ws_v3": "reports-app-volcan-erp/posicao-do-dia"
    }
}